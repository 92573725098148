import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Approach from "../components/Approach";

let IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Our Approach" />
    <Approach data={data} />
  </Layout>
);

export const query = graphql`query ApproachImgQuery {
  allFile(filter: {relativeDirectory: {eq: "our-approach"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`;

export default IndexPage;
