import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TimelineMax, Power0, Sine } from "gsap";

export default class LogoAnimationMobile extends Component {
  constructor() {
    super();

    this.line1 = null;
    this.line2 = null;
    this.line3 = null;
    this.line4 = null;
    this.line5 = null;
    this.line6 = null;
    this.line7 = null;
    this.line8 = null;
    this.line9 = null;
    this.line10 = null;
    this.line11 = null;
    this.line12 = null;
    this.line13 = null;
    this.line14 = null;
    this.line15 = null;
    this.line16 = null;
    this.line17 = null;
    this.line18 = null;
    this.logo = null;
    this.logo1 = null;
    this.logo2 = null;
    this.logo3 = null;
    this.logoFill1 = null;
    this.logoFill2 = null;
    this.logoFill3 = null;
    this.lines = null;
    this.vectr = null;
    this.tl = null;
    this.logoTL = null;
    this.btn = null;
    this.graphics = null;
    this.logoContainer = null;
    this.dottedLine = null;
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      require("../../../../assets/js/DrawSVGPlugin");

      this.tl = new TimelineMax({ paused: true })
        .set(this.lines, { autoAlpha: 1 })
        .fromTo(
          this.line2,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line3,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line4,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line5,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line6,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line7,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line8,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line9,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line10,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line11,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line12,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line13,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line14,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line15,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line16,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line17,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line18,
          1.2,
          { drawSVG: "0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .set(this.logo3, { autoAlpha: 1 })
        .set(this.logo1, { autoAlpha: 1 })
        .set(this.logo2, { autoAlpha: 1 })
        .fromTo(
          this.logo3,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut }
        )
        .to(this.logoFill3, 0.7, {
          autoAlpha: 1,
          fill: "#fec659",
          ease: Power0.easeOut
        })
        .fromTo(
          this.logo2,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill2, 0.7, {
          autoAlpha: 1,
          fill: "#fec659",
          ease: Power0.easeOut
        })
        .fromTo(
          this.logo1,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill1, 0.7, {
          autoAlpha: 1,
          fill: "#fec659",
          ease: Power0.easeOut
        })
        .set(this.dottedLine, { autoAlpha: 1 })
        .fromTo(
          this.dottedLine,
          1.5,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          }
        )
        .delay(1.5);

      this.tl.play();
    }
  }
  render() {
    return (
      <div className={styles.logo} ref={lines => (this.lines = lines)}>
        <div></div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 769.72 1025.27">
          <line
            className="yellowLine"
            x1="385.48"
            y1="528.27"
            x2="385.48"
            y2="-"
            ref={line => (this.dottedLine = line)}
          />
          <line
            className="whiteLine"
            x1="385.48"
            y1=""
            x2="385.48"
            y2="528.27"
          />
          <g className={styles.cls2}>
            <line
              className={styles.cls3}
              x1="0.15"
              y1="231"
              x2="457.67"
              y2="1025.18"
              ref={line => (this.line2 = line)}
            />
            <line
              className={styles.cls3}
              x1="507.11"
              y1="1025.18"
              x2="0.93"
              y2="146.53"
              ref={line => (this.line3 = line)}
            />
            <line
              className={styles.cls3}
              x1="568.21"
              y1="1025.18"
              x2="1.5"
              y2="41.45"
              ref={line => (this.line4 = line)}
            />
            <line
              className={styles.cls3}
              x1="0.48"
              y1="89.34"
              x2="539.6"
              y2="1025.18"
              ref={line => (this.line5 = line)}
            />
            <line
              className={styles.cls3}
              x1="769.21"
              y1="149.33"
              x2="264.65"
              y2="1025.18"
              ref={line => (this.line6 = line)}
            />
            <line
              className={styles.cls3}
              x1="231.88"
              y1="1025.18"
              x2="769.21"
              y2="92.44"
              ref={line => (this.line7 = line)}
            />
            <line
              className={styles.cls3}
              x1="769.21"
              y1="44.23"
              x2="204.1"
              y2="1025.18"
              ref={line => (this.line8 = line)}
            />
            <line
              className={styles.cls3}
              x1="313.81"
              y1="1025.18"
              x2="768.84"
              y2="235.32"
              ref={line => (this.line9 = line)}
            />
            <line
              className={styles.cls3}
              x1="572.12"
              y1="0.18"
              x2="0.83"
              y2="991.85"
              ref={line => (this.line10 = line)}
            />
            <line
              className={styles.cls3}
              x1="31.07"
              y1="1025.18"
              x2="621.61"
              y2="0.09"
              ref={line => (this.line11 = line)}
            />
            <line
              className={styles.cls3}
              x1="769.57"
              y1="988.53"
              x2="200.19"
              y2="0.18"
              ref={line => (this.line12 = line)}
            />
            <line
              className={styles.cls3}
              x1="150.7"
              y1="0.09"
              x2="741.24"
              y2="1025.18"
              ref={line => (this.line13 = line)}
            />
            <line
              className={styles.cls3}
              x1="0.64"
              y1="528.27"
              x2="769.21"
              y2="528.27"
              ref={line => (this.line14 = line)}
            />
            <line
              className={styles.cls3}
              x1="769.21"
              y1="571.04"
              x2="0.64"
              y2="571.04"
              ref={line => (this.line15 = line)}
            />
            <line
              className={styles.cls3}
              x1="769.21"
              y1="623.54"
              x2="0.48"
              y2="623.54"
              ref={line => (this.line16 = line)}
            />
            <line
              className={styles.cls3}
              x1="0.93"
              y1="599.37"
              x2="769.21"
              y2="599.37"
              ref={line => (this.line17 = line)}
            />
            <line
              className={styles.cls3}
              x1="0.15"
              y1="773.8"
              x2="769.21"
              y2="773.8"
              ref={line => (this.line18 = line)}
            />
            <line
              className={styles.cls3}
              x1="769.21"
              y1="816.57"
              x2="155.92"
              y2="816.57"
              ref={line => (this.line1 = line)}
            />
            <line
              className={styles.cls3}
              x1="183.49"
              y1="57.01"
              x2="183.49"
              y2="57.01"
              ref={line => (this.line1 = line)}
            />
          </g>
          <polyline
            className={styles.cls4}
            points="385.08 816.5 351.94 816.5 376.64 773.72 409.34 773.71 495.98 623.66 545.36 623.66 434.02 816.5 385.08 816.5"
            ref={logo => (this.logo1 = logo)}
          />
          <polygon
            className={styles.cls4}
            points="281.65 528.24 504.32 528.24 545.36 599.32 495.96 599.32 479.61 571.01 306.34 571.01 281.65 528.24"
            ref={logo => (this.logo2 = logo)}
          />
          <polygon
            className={styles.cls4}
            points="330.79 804.96 219.45 612.12 260.49 541.03 285.19 583.82 268.85 612.14 355.48 762.19 330.79 804.96"
            ref={logo => (this.logo3 = logo)}
          />
          <polyline
            className={styles.logoFill}
            points="385.08 816.5 351.94 816.5 376.64 773.72 409.34 773.71 495.98 623.66 545.36 623.66 434.02 816.5 385.08 816.5"
            ref={logo => (this.logoFill1 = logo)}
          />
          <polygon
            className={styles.logoFill}
            points="281.65 528.24 504.32 528.24 545.36 599.32 495.96 599.32 479.61 571.01 306.34 571.01 281.65 528.24"
            ref={logo => (this.logoFill2 = logo)}
          />
          <polygon
            className={styles.logoFill}
            points="330.79 804.96 219.45 612.12 260.49 541.03 285.19 583.82 268.85 612.14 355.48 762.19 330.79 804.96"
            ref={logo => (this.logoFill3 = logo)}
          />
        </svg>
      </div>
    );
  }
}
