import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TimelineMax, Power2 } from "gsap";

export default class index extends Component {
  constructor() {
    super();

    this.circle = null;
    this.graphics1 = null;
    this.graphics2 = null;
    this.graphics3 = null;
    this.tl = null;
  }
  componentDidMount() {
    const { delay } = this.props;
    this.tl = new TimelineMax({ paused: true })
      .to(this.circle, 1, { fill: "#fec659", ease: Power2.easeOut })
      .to(this.graphics1, 1, { fill: "#fff", ease: Power2.easeOut }, "0")
      .to(this.graphics2, 1, { fill: "#fff", ease: Power2.easeOut }, "0")
      .to(this.graphics3, 1, { fill: "#fff", ease: Power2.easeOut }, "0")
      .delay(delay * 0.14 + 1);
  }
  componentDidUpdate() {
    const { isVisible } = this.props;

    if (isVisible) {
      this.tl.play();
    }
  }
  render() {
    const { cx, cy, p1, p2, p3 } = this.props;
    return (
      <>
        <circle
          className={styles.a1}
          cx={cx}
          cy={cy}
          r="26.4"
          ref={circle => (this.circle = circle)}
        />
        <polyline
          className={styles.a1}
          points={p1}
          ref={graphics => (this.graphics1 = graphics)}
        />
        <polygon
          className={styles.a1}
          points={p2}
          ref={graphics => (this.graphics2 = graphics)}
        />
        <polygon
          className={styles.a1}
          points={p3}
          ref={graphics => (this.graphics3 = graphics)}
        />
      </>
    );
  }
}
