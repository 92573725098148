import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TweenMax, TimelineMax, Sine } from "gsap";
import { Link } from "gatsby";
import { approachSections } from '../../utils/helperFunctions'
export default class ApproachTwo extends Component {
 constructor() {
    super();
    this.line1 = null;
    this.line2 = null;

    this.logos = null;
    this.logoPart1A = null;
    this.logoPart1B = null;
    this.logoPart1C = null;
    this.logoPart2A = null;
    this.logoPart2B = null;
    this.logoPart2C = null;
    this.logoPart3A = null;
    this.logoPart3B = null;
    this.logoPart3C = null;
    this.logoPart4A = null;
    this.logoPart4B = null;
    this.logoPart4C = null;
    this.logoPart5A = null;
    this.logoPart5B = null;
    this.logoPart5C = null;
    this.text = null;

    this.tl = null;
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      require("../../../assets/js/DrawSVGPlugin");
      TweenMax.set(this.line2, { autoAlpha: 0 });
      TweenMax.set(this.line1, { autoAlpha: 0 });
      this.tl = new TimelineMax({ paused: true })
        .fromTo(
          this.logoPart1A,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut }
        )
        .fromTo(
          this.logoPart1B,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "0"
        )
        .fromTo(
          this.logoPart1C,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "0"
        )
        .fromTo(
          this.logoPart2A,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut }
        )
        .fromTo(
          this.logoPart2B,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart2C,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart3A,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart3B,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart3C,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart4A,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut }
        )
        .fromTo(
          this.logoPart4B,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart4C,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart5A,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart5B,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .fromTo(
          this.logoPart5C,
          0.4,
          { fill: "#fec659" },
          { fill: "#ccc", ease: Sine.easeOut },
          "-=.4"
        )
        .set(this.line1, { autoAlpha: 1 })
        .fromTo(
          this.line1,
          1.2,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          }
        )
        .to(this.text, 1, { autoAlpha: 1, ease: Sine.easeOut }, "-=.5")
        .set(this.line2, { autoAlpha: 1 })
        .fromTo(
          this.line2,
          2,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          }
        );
    }
  }
  componentDidUpdate() {
    const { activeSection } = this.props;

    if (activeSection === approachSections.SECTION_TWO) {
      if (typeof window !== "undefined") {
        this.tl.play();
      }
    }
  }
  render() {
    return (
      <section className={`${styles.section} relative h-full`}>
        <div className="container--approach">
          <div className="approach-flexContainer">
            <div className="approach-svg-container">
              <svg
                className="small"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 468 1824"
              >
                <line
                  className="yellowLine reduced"
                  x1="234"
                  y1="1824"
                  x2="234"
                  y2="1150"
                  ref={line => (this.line1 = line)}
                />
                <line
                  className="whiteLine reduced"
                  x1="234"
                  y1="1150"
                  x2="234"
                  y2="1824"
                />
                <line
                  className="yellowLine reduced"
                  x1="234"
                  x2="234"
                  y1="674"
                  y2="0"
                  ref={line => (this.line2 = line)}
                />
                <line
                  className="whiteLine reduced"
                  x1="234"
                  x2="234"
                  y2="674"
                />
                <polyline
                  className={styles.cls3}
                  points="237.39 988.91 256.81 988.91 242.33 1013.98 223.17 1013.98 172.43 1101.89 143.5 1101.89 208.72 988.91 237.39 988.91"
                  ref={logo => (this.logoPart1A = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="297.99 1157.79 167.54 1157.79 143.5 1116.15 172.44 1116.15 182.02 1132.73 283.53 1132.73 297.99 1157.79"
                  ref={logo => (this.logoPart1B = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="269.2 995.68 334.43 1108.65 310.38 1150.3 295.91 1125.22 305.49 1108.64 254.73 1020.73 269.2 995.68"
                  ref={logo => (this.logoPart1C = logo)}
                />

                <polyline
                  className={styles.cls3}
                  points="181.22 946.43 190.89 963.26 161.95 963.2 152.41 946.59 50.9 946.37 36.5 921.27 166.95 921.57 181.22 946.43"
                  ref={logo => (this.logoPart2A = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="64.97 1083.08 0 969.96 24.13 928.38 38.55 953.47 28.94 970.04 79.5 1058.06 64.97 1083.08"
                  ref={logo => (this.logoPart2B = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="191.21 977.38 125.73 1090.2 77.64 1090.1 92.17 1065.07 111.33 1065.1 162.27 977.32 191.21 977.38"
                  ref={logo => (this.logoPart2C = logo)}
                />
                <polyline
                  className={styles.cls3}
                  points="458.02 946.43 467.69 963.26 438.75 963.2 429.21 946.59 327.7 946.37 313.29 921.27 443.74 921.57 458.02 946.43"
                  ref={logo => (this.logoPart3A = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="341.77 1083.08 276.79 969.96 300.93 928.38 315.35 953.47 305.73 970.04 356.28 1058.06 341.77 1083.08"
                  ref={logo => (this.logoPart3B = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="468 977.38 402.52 1090.2 354.43 1090.1 368.96 1065.07 388.12 1065.1 439.07 977.32 468 977.38"
                  ref={logo => (this.logoPart3C = logo)}
                />
                <polyline
                  className={styles.cls3}
                  points="286.77 876.57 277.11 859.74 306.04 859.8 315.59 876.4 417.1 876.63 431.5 901.72 301.05 901.43 286.77 876.57"
                  ref={logo => (this.logoPart4A = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="403.03 739.91 468 853.04 443.87 894.62 429.45 869.52 439.06 852.96 388.5 764.94 403.03 739.91"
                  ref={logo => (this.logoPart4B = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="276.79 845.62 342.27 732.8 390.36 732.9 375.83 757.93 356.67 757.9 305.73 845.68 276.79 845.62"
                  ref={logo => (this.logoPart4C = logo)}
                />
                <polyline
                  className={styles.cls3}
                  points="9.98 876.57 0.31 859.74 29.25 859.8 38.79 876.4 140.3 876.63 154.71 901.72 24.26 901.43 9.98 876.57"
                  ref={logo => (this.logoPart5A = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="126.23 739.91 191.21 853.04 167.07 894.62 152.65 869.52 162.27 852.96 111.72 764.94 126.23 739.91"
                  ref={logo => (this.logoPart5B = logo)}
                />
                <polygon
                  className={styles.cls3}
                  points="0 845.62 65.48 732.8 113.57 732.9 99.04 757.93 79.88 757.9 28.93 845.68 0 845.62"
                  ref={logo => (this.logoPart5C = logo)}
                />
                <polyline
                  className={styles.cls3}
                  points="230.61 834.07 211.19 834.07 225.67 809.02 244.83 809.02 295.57 721.11 324.5 721.11 259.28 834.07 230.61 834.07"
                />
                <polygon
                  className={styles.cls3}
                  points="170.01 665.21 300.46 665.21 324.5 706.85 295.56 706.85 285.98 690.27 184.47 690.27 170.01 665.21"
                />
                <polygon
                  className={styles.cls3}
                  points="198.81 827.32 133.57 714.35 157.62 672.7 172.09 697.76 162.51 714.36 213.27 802.27 198.81 827.32"
                />
              </svg>
            </div>
            <div className="approach-textContainerWrapper">
              <div
                className="approach-textContainer"
                ref={text => (this.text = text)}
              >
                <h3>FINDING YOUR FIT (AND OURS)</h3>
                <p>
                  Vectr invests primarily in Seed to Series A companies and
                  looks for well-rounded teams led by knowledgeable founders, who
                  are also great leaders. If you are building an exciting
                  company that needs funding, fill out some basic
                  information{" "}
                  <Link to="/contact" className="email-link">
                    here
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
