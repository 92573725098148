import React, { Component } from "react";
import * as styles from "./index.module.scss";
import PageTitle from "../../utils/PageTitle";
import { TweenMax, Sine } from "gsap";
import NextSectionButton from "../../utils/Button/NextSectionButton";
import Animation from "./Animation";
import AnimationDesktop from "./AnimationDesktop";

export default class ApproachIntro extends Component {
  constructor() {
    super();
    this.btn = null;
  }

  componentDidMount() {
    setTimeout(() => {
      TweenMax.to(this.btn, 1, { autoAlpha: 1, ease: Sine.easeOut });
    }, 5000);
  }

  render() {
    return (
      <section
        className={`${styles.section} relative h-full`}
        id="approachIntro"
      >
        <AnimationDesktop />
        <Animation />
        <div className={styles.pageTitleContainer}>
          <PageTitle title="Our Approach" color="white" />
        </div>
        <div className={styles.btnContainer} ref={btn => (this.btn = btn)}>
          <NextSectionButton
            method={() => this.props.toNextSection()}
            type="up"
            textColor="black"
            textStyle="thin"
          >
            Scroll Up
          </NextSectionButton>
        </div>
      </section>
    );
  }
}
