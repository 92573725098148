import React, { Component } from "react";
import * as styles from "./index.module.scss";
import arrowGroup1 from "../../../images/approach/Approach-3-PortfolioBoost (1).svg";
import arrowGroup2 from "../../../images/approach/Approach-3-PortfolioBoost (2).svg";
import arrowGroup3 from "../../../images/approach/Approach-3-PortfolioBoost (3).svg";
import { TimelineMax, Sine } from "gsap";
import { approachSections } from '../../utils/helperFunctions'
export default class SectionFour extends Component {
  constructor() {
    super();
    this.line1 = null;
    this.line2 = null;
    this.line1Mobile = null;
    this.line2Mobile = null;
    this.line1W = null;
    this.line2W = null;
    this.line1MobileW = null;
    this.line2MobileW = null;
    this.arrowGroup1 = null;
    this.arrowGroup2 = null;
    this.arrowGroup3 = null;
    this.text = null;
    this.tl = null;
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      require("../../../assets/js/DrawSVGPlugin");

      this.tl = new TimelineMax({ paused: true })
        .set(this.line1, { autoAlpha: 1 })
        .fromTo(
          this.line1,
          1.5,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut,
            onComplete: () => {
              this.arrowGroup3.classList.add(styles.animate);
              setTimeout(() => {
                this.arrowGroup2.classList.add(styles.animate);
              }, 300);
              setTimeout(() => {
                this.arrowGroup1.classList.add(styles.animate);
              }, 600);
            }
          }
        )
        .set(this.line2, { autoAlpha: 1 }, "+=1.2")
        .fromTo(
          this.line2,
          3,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          }
        )
        .to(this.text, 0.7, { autoAlpha: 1, ease: Sine.easeOut }, "-=2.5");
    }
  }

  componentDidUpdate() {
    const { activeSection } = this.props;

    if (activeSection === approachSections.SECTION_FOUR) {
      if (typeof window !== "undefined") {
        this.tl.play();
      }
    }
  }
  render() {
    return (
      <section className={`${styles.section} relative h-full`}>
        <div className="container--approach">
          <div className="approach-flexContainer">
            <div className="approach-svg-container">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468 1824">
                <line
                  className="yellowLine"
                  x1="234"
                  y1="1824"
                  x2="234"
                  y2="960"
                  ref={line => (this.line1 = line)}
                />
                <line
                  className="yellowLine"
                  x1="234"
                  x2="234"
                  y2="0"
                  y1="810"
                  ref={line => (this.line2 = line)}
                />
                <line
                  className="whiteLine"
                  x1="234"
                  y1="960"
                  x2="234"
                  y2="1824"
                />
                <line className="whiteLine" x1="234" x2="234" y1="810" y2="0" />
              </svg>
              <div className={styles.arrows}>
                <div
                  className={styles.arrowGroup}
                  ref={arrowGroup => (this.arrowGroup1 = arrowGroup)}
                >
                  <img src={arrowGroup3} alt="" />
                </div>
                <div
                  className={styles.arrowGroup}
                  ref={arrowGroup => (this.arrowGroup2 = arrowGroup)}
                >
                  <img src={arrowGroup2} alt="" />
                </div>
                <div
                  className={styles.arrowGroup}
                  ref={arrowGroup => (this.arrowGroup3 = arrowGroup)}
                >
                  <img src={arrowGroup1} alt="" />
                </div>
              </div>
            </div>
            <div className="approach-textContainerWrapper">
              <div
                className="approach-textContainer"
                ref={text => (this.text = text)}
              >
                <h3>GIVING OUR PORTFOLIO AN ADDED BOOST</h3>
                <p>
                  Making an investment is just the start. We support our
                  companies by providing product development and
                  commercialization support through our Studio and Growth teams.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
