import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import NextSectionButton from '../utils/Button/NextSectionButton';
import { TweenMax, Sine } from 'gsap';

import Intro from './Intro';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import SectionSix from './SectionSix';
import SectionIndicator from '../utils/SectionIndicator';
import { approachSections } from '../utils/helperFunctions';

export default class Approach extends Component {
	constructor(props) {
		super(props);

		// Ref
		this.fullpage = null;
		this.state = { slideIndex: approachSections.INTRO };
		this.btn = null;
	}

	componentDidUpdate() {
		if (
			this.state.slideIndex === approachSections.INTRO ||
			this.state.slideIndex === approachSections.SECTION_SIX
		) {
			TweenMax.to(this.btn, 1, { autoAlpha: 0, y: 20, ease: Sine.easeOut });
		} else {
			TweenMax.to(this.btn, 1, { autoAlpha: 1, y: 0, ease: Sine.easeOut });
		}
	}

	goToSlide = i => {
		this.fullpage.fullpageApi.moveTo(i);
	};

	moveUp = () => {
		this.fullpage.fullpageApi.moveSectionUp();
	};

	render() {
		return (
			<>
				<SectionIndicator
					slideIndex={this.state.slideIndex}
					goToSlide={this.goToSlide}
					numOfSlides={Object.keys(approachSections).length}
				/>
				<div
					className={`absolute right-0 bottom-0 opacity-0 z-10 mr-16 mb-4`}
					ref={btn => (this.btn = btn)}
				>
					<NextSectionButton
						method={this.moveUp}
						textColor="black"
						textStyle="thin"
						type="up"
					/>
				</div>
				<ReactFullpage
					licenseKey={'CE798CC3-AD9741AE-BE07A1F5-C1A9DB36'}
					ref={slider => (this.fullpage = slider)}
					scrollingSpeed={1000}
					dragAndMove={true}
					onLeave={(origin, destination, direction) => {
						this.setState({
							slideIndex: destination.index
						});
					}}
					render={({ fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<div className="section">
									<SectionSix activeSection={this.state.slideIndex} />
								</div>
								<div className="section">
									<SectionFive activeSection={this.state.slideIndex} />
								</div>
								<div className="section">
									<SectionFour activeSection={this.state.slideIndex} />
								</div>
								<div className="section">
									<SectionThree activeSection={this.state.slideIndex} />
								</div>
								<div className="section">
									<SectionTwo activeSection={this.state.slideIndex} />
								</div>
								<div className="section active">
									<Intro
										scrollTarget={this.fullPageSlider}
										toNextSection={() =>
											fullpageApi.moveTo(approachSections.SECTION_TWO)
										}
									/>
								</div>
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</>
		);
	}
}
