import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TweenMax, TimelineMax, Power0, Sine } from "gsap";
import { approachSections } from '../../utils/helperFunctions'
class ApproachThree extends Component {
  constructor() {
    super();

    this.dottedLine = null;
    this.circle1 = null;
    this.circle2 = null;
    this.circle3 = null;
    this.circle4 = null;
    this.circle5 = null;
    this.circle6 = null;
    this.circle7 = null;
    this.circle8 = null;
    this.circle9 = null;
    this.circleFinal = null;
    this.logoPart1 = null;
    this.logoPart2 = null;
    this.logoPart3 = null;
    this.text = null;
    this.tl = null;
    this.CircleTL = null;
    this.btn = null;
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      require("../../../assets/js/DrawSVGPlugin");

      TweenMax.set(this.dottedLine, { autoAlpha: 0 });

      this.tl = new TimelineMax({ paused: true }).fromTo(
        this.dottedLine,
        4,
        { drawSVG: "0" },
        { drawSVG: "100%", ease: Power0.easeOut }
      );

      this.CircleTL = new TimelineMax({ paused: true })
        .fromTo(
          this.circle1,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "+=1.2"
        )
        .fromTo(
          this.circle2,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=.15"
        )
        .fromTo(
          this.circle3,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=0"
        )
        .fromTo(
          this.circle4,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=0"
        )
        .fromTo(
          this.circle5,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=0"
        )
        .fromTo(
          this.circle6,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=.15"
        )
        .fromTo(
          this.circle7,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=.15"
        )
        .fromTo(
          this.circle8,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=.15"
        )
        .fromTo(
          this.circle9,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=.15"
        )
        .fromTo(
          this.circleFinal,
          0.3,
          { fill: "#ccc" },
          { fill: "#fec659", ease: Sine.easeOut },
          "-=.5"
        )
        .fromTo(
          this.logoPart1,
          0.3,
          { autoAlpha: 0 },
          { autoAlpha: 1, ease: Sine.easeOut },
          "-=.3"
        )
        .fromTo(
          this.logoPart2,
          0.3,
          { autoAlpha: 0 },
          { autoAlpha: 1, ease: Sine.easeOut },
          "-=.3"
        )
        .fromTo(
          this.logoPart3,
          0.3,
          { autoAlpha: 0 },
          { autoAlpha: 1, ease: Sine.easeOut },
          "-=.3"
        )
        .to(this.text, 1.5, { autoAlpha: 1, ease: Sine.easeOut }, "-=1");
    }
  }
  componentDidUpdate() {
    const { activeSection } = this.props;

    if (activeSection === approachSections.SECTION_THREE) {
      if (typeof window !== "undefined") {
        TweenMax.set(this.dottedLine, { autoAlpha: 1 });
        this.tl.play();
        this.CircleTL.play();
      }
    }
  }

  render() {
    return (
      <section className={`${styles.section} relative h-full`} id="approachTwo">
        <div className="container--approach">
          <div className="approach-flexContainer">
            <div className="approach-svg-container">
              <div className={styles.svg}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 459.57 1825"
                >
                  <polyline
                    className="yellowLine"
                    points="229.785 1825 229.785 1022.46 234.2 1028.69 161.24 1028.69 127.77 995.19 153 958.76 234.2 958.76 301.06 958.76 332.59 929.17 332.59 929.17 301.69 901.39 231.88 901.39 211.99 886.94 211.99 886.94 230.67 870.46 301.48 870.46 301.48 870.25 328.34 843.39 288.65 804.65 192.67 804.65 159.93 771.91 192.24 739.6 232.62 739.96 232.61 740.03 229.785 738 229.785 0"
                    ref={line => (this.dottedLine = line)}
                  />
                  <polyline
                    className="whiteLine"
                    points="229.785 1825 229.785 1022.46 234.2 1028.69 161.24 1028.69 127.77 995.19 153 958.76 234.2 958.76 301.06 958.76 332.59 929.17 332.59 929.17 301.69 901.39 231.88 901.39 211.99 886.94 211.99 886.94 230.67 870.46 301.48 870.46 301.48 870.25 328.34 843.39 288.65 804.65 192.67 804.65 159.93 771.91 192.24 739.6 232.62 739.96 232.61 740.03 229.785 738 229.785 0"
                  />

                  <path
                    className={styles.cls1}
                    d="M163.68,771.82"
                    transform="translate(-3.66 0)"
                  />
                  <polyline
                    className={styles.cls2}
                    points="334.78 971.45 346.11 960.36 380.92 960.36"
                  />
                  <polyline
                    className={styles.cls2}
                    points="373.23 878.7 414.59 878.7 438.32 850.33"
                  />
                  <polyline
                    className={styles.cls2}
                    points="79.81 877.86 97.17 864.51 178.4 864.51 199.82 837.12 291.8 837.12"
                  />
                  <polyline
                    className={styles.cls2}
                    points="298.17 870.46 315.84 886.94 342.39 886.94"
                  />

                  <polyline
                    className={styles.cls2}
                    points="285.8 929.17 213.01 929.17 157.6 895.06 129.65 895.06"
                  />
                  <polyline
                    className={styles.cls2}
                    points="127.77 995.19 84.05 995.19 68.94 973.03 93.9 935.53 144.29 935.53"
                  />
                  <polyline
                    className={styles.cls2}
                    points="234.2 1028.69 346.23 1028.69 380.92 995.19 423.63 995.19"
                  />
                  <polyline
                    className={styles.cls2}
                    points="234.2 1031.66 259.29 998.19 315.84 998.19"
                  />
                  <polyline
                    className={styles.cls2}
                    points="175.09 992.74 214.04 992.74 234.2 958.76"
                  />

                  <circle
                    className={styles.cls4}
                    cx="380.92"
                    cy="995.19"
                    r="12.96"
                  />
                  <circle
                    className={styles.cls4}
                    cx="315.84"
                    cy="998.19"
                    r="11.64"
                  />
                  <circle
                    className={styles.cls4}
                    cx="68.94"
                    cy="973.03"
                    r="10"
                  />

                  <circle
                    className={styles.cls4}
                    cx="291.8"
                    cy="837.12"
                    r="6.12"
                  />
                  <circle
                    className={styles.cls4}
                    cx="319.9"
                    cy="807.2"
                    r="6.12"
                  />
                  <circle
                    className={styles.cls4}
                    cx="98.58"
                    cy="785.45"
                    r="6.12"
                  />
                  <circle
                    className={styles.cls4}
                    cx="409.26"
                    cy="940.49"
                    r="5.33"
                  />
                  <circle
                    className={styles.cls4}
                    cx="144.29"
                    cy="935.53"
                    r="8"
                  />
                  <circle
                    className={styles.cls4}
                    cx="129.65"
                    cy="895.06"
                    r="8"
                  />
                  <circle
                    className={styles.cls4}
                    cx="75.27"
                    cy="916.63"
                    r="8"
                  />
                  <circle
                    className={styles.cls4}
                    cx="79.81"
                    cy="877.86"
                    r="8"
                  />
                  <circle className={styles.cls4} cx="154.69" cy="831" r="8" />
                  <circle
                    className={styles.cls4}
                    cx="430.32"
                    cy="995.19"
                    r="8"
                  />
                  <circle
                    className={styles.cls4}
                    cx="382.81"
                    cy="960.36"
                    r="9.58"
                  />
                  <circle
                    className={styles.cls4}
                    cx="206.21"
                    cy="1059.25"
                    r="9.58"
                  />
                  <circle
                    className={styles.cls4}
                    cx="124.51"
                    cy="1036.89"
                    r="6.3"
                  />
                  <circle
                    className={styles.cls4}
                    cx="400.23"
                    cy="911.06"
                    r="9.03"
                  />
                  <circle
                    className={styles.cls4}
                    cx="438.32"
                    cy="850.32"
                    r="9.03"
                  />
                  <circle
                    className={styles.cls4}
                    cx="334.78"
                    cy="971.45"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="263.63"
                    cy="1059.25"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="341.14"
                    cy="887.06"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="453.21"
                    cy="895.06"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="373.23"
                    cy="878.7"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="346.86"
                    cy="807.36"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="383.68"
                    cy="850.32"
                    r="6.36"
                  />

                  <circle
                    className={styles.cls4}
                    cx="285.47"
                    cy="929.17"
                    r="6.36"
                  />
                  <circle
                    className={styles.cls4}
                    cx="183.81"
                    cy="911.07"
                    r="11.3"
                  />
                  <circle
                    className={styles.cls4}
                    cx="178.4"
                    cy="864.51"
                    r="11.3"
                  />

                  <circle
                    className={styles.cls4}
                    cx="202.03"
                    cy="776.18"
                    r="9.27"
                  />
                  <circle
                    className={styles.cls4}
                    cx="114.38"
                    cy="831.4"
                    r="7.17"
                  />

                  <polyline
                    className={styles.cls2}
                    points="332.59 929.17 350.79 911.06 400.23 911.06 438.32 911.06 453.21 895.06"
                  />
                  <line
                    className={styles.cls2}
                    x1="382.81"
                    y1="960.36"
                    x2="409.26"
                    y2="940.49"
                  />
                  <circle
                    className={styles.cls4}
                    cx="347.5"
                    cy="768.77"
                    r="5.33"
                  />
                  <circle
                    className={styles.cls4}
                    cx="291.02"
                    cy="741.99"
                    r="6.36"
                  />
                  <polyline
                    className={styles.cls2}
                    points="291.02 741.99 302.35 753.09 328.79 753.09 347.1 768.77"
                  />

                  <circle
                    className={styles.cls4}
                    cx="175.09"
                    cy="992.74"
                    r="8"
                  />

                  <polyline
                    className={styles.cls2}
                    points="319.9 807.2 287.65 776.18 202.03 776.18"
                  />
                  <polyline
                    className={styles.cls2}
                    points="98.58 785.46 116 771.82 160.02 771.82"
                  />
                  <polyline
                    className={styles.cls2}
                    points="347.5 807.2 384.85 807.2 397.56 791.69 430.32 791.69"
                  />
                  <polyline
                    className={styles.cls2}
                    points="19.42 936.25 59.74 936.25 75.27 916.63"
                  />
                  <line
                    className={styles.cls2}
                    x1="263.63"
                    y1="1059.25"
                    x2="357.85"
                    y2="1059.25"
                  />

                  <polyline
                    className={styles.cls6}
                    points="70.65 810.04 21.29 810.04 1.11 791.53"
                  />
                  <polyline
                    className={styles.cls6}
                    points="89.35 1079.48 121.37 1109.04 195.28 1109.04"
                  />
                  <circle
                    className={styles.cls3}
                    cx="230.2"
                    cy="1028.69"
                    r="13.77"
                    ref={circle => (this.circle1 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="127.77"
                    cy="995.19"
                    r="14.16"
                    ref={circle => (this.circle2 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="230.2"
                    cy="958.76"
                    r="11.79"
                    ref={circle => (this.circle3 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="332.59"
                    cy="929.17"
                    r="11.32"
                    ref={circle => (this.circle4 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="211.99"
                    cy="886.94"
                    r="8.11"
                    ref={circle => (this.circle5 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="301.48"
                    cy="870.25"
                    r="13.77"
                    ref={circle => (this.circle6 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="328.72"
                    cy="843.74"
                    r="6.36"
                    ref={circle => (this.circle7 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="288.42"
                    cy="804.65"
                    r="6.36"
                    ref={circle => (this.circle8 = circle)}
                  />
                  <circle
                    className={styles.cls3}
                    cx="160.1"
                    cy="772.06"
                    r="9.27"
                    ref={circle => (this.circle9 = circle)}
                  />

                  <circle
                    className={styles.cls3}
                    cx="230.56"
                    cy="740.13"
                    r="24.17"
                    ref={circle => (this.circleFinal = circle)}
                  />
                  <polyline
                    className={styles.cls5}
                    points="229.78 753.12 227.04 753.12 229.08 749.58 231.79 749.58 238.96 737.16 243.04 737.16 233.83 753.12 229.78 753.12"
                    ref={logo => (this.logoPart1 = logo)}
                  />
                  <polygon
                    className={styles.cls5}
                    points="221.22 729.27 239.65 729.27 243.04 735.15 238.95 735.15 237.6 732.81 223.27 732.81 221.22 729.27"
                    ref={logo => (this.logoPart2 = logo)}
                  />
                  <polygon
                    className={styles.cls5}
                    points="226.29 752.16 217.08 736.21 220.47 730.33 222.52 733.87 221.17 736.21 228.33 748.62 226.29 752.16"
                    ref={logo => (this.logoPart3 = logo)}
                  />
                </svg>
              </div>
            </div>
            <div className="approach-textContainerWrapper">
              <div
                className="approach-textContainer"
                ref={text => (this.text = text)}
              >
                <h3>SAY HELLO!</h3>
                <p>
                  Venture Capital is a people business. At Vectr, we value
                  a warm intro as it not only shows that you are resourceful,
                  but more importantly, that you invested time and effort to get
                  connected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ApproachThree;
