import React, { Component } from "react";
import * as styles from "./index.module.scss";
import mapAll from "../../../images/approach/map-all.svg";
import map1 from "../../../images/approach/map-1.svg";
import map2 from "../../../images/approach/map-2.svg";
import map3 from "../../../images/approach/map-3.svg";
import map4 from "../../../images/approach/map-4.svg";
import map5 from "../../../images/approach/map-5.svg";
import { TweenMax, TimelineMax, Sine } from "gsap";
import { approachSections } from '../../utils/helperFunctions'
export default class SectionFive extends Component {
  constructor() {
    super();
    this.line1 = null;
    this.line2 = null;
    this.map1 = null;
    this.map2 = null;
    this.map3 = null;
    this.map4 = null;
    this.map5 = null;
    this.text = null;
    this.tl = null;
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      require("../../../assets/js/DrawSVGPlugin");
      this.tl = new TimelineMax({ paused: true })
        .set(this.line1, { autoAlpha: 1 })
        .fromTo(
          this.line1,
          2,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          }
        )
        .to(this.map1, 0.8, { autoAlpha: 1, ease: Sine.easeOut })
        .to(this.map2, 0.8, { autoAlpha: 1, ease: Sine.easeOut }, "-=.3")
        .to(this.map3, 0.8, { autoAlpha: 1, ease: Sine.easeOut }, "-=.3")
        .to(this.map4, 0.8, { autoAlpha: 1, ease: Sine.easeOut }, "-=.3")
        .to(this.map5, 0.8, { autoAlpha: 1, ease: Sine.easeOut }, "-=.3")
        .to(this.text, 1.5, { autoAlpha: 1, ease: Sine.easeOut }, "-=.2")
        .set(this.line2, { autoAlpha: 1 }, "-=1")
        .fromTo(
          this.line2,
          3,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          },
          "-=1"
        );
    }
    TweenMax.set(this.line2, { autoAlpha: 0 });
    TweenMax.set(this.line1, { autoAlpha: 0 });
  }

  componentDidUpdate() {
    const { activeSection } = this.props;

    if (activeSection === approachSections.SECTION_FIVE) {
      if (typeof window !== "undefined") {
        this.tl.play();
      }
    }
  }
  render() {
    return (
      <section className={`${styles.section} relative h-full`}>
        <div className="container--approach">
          <div className="approach-flexContainer">
            <div className="approach-svg-container">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468 1824">
                <line
                  className="yellowLine"
                  x1="234"
                  y1="1824"
                  x2="234"
                  y2="870"
                  ref={line => (this.line1 = line)}
                />
                <line
                  className="yellowLine"
                  x1="234"
                  x2="234"
                  y1="750"
                  y2="0"
                  ref={line => (this.line2 = line)}
                />
                <line
                  className="whiteLine"
                  x1="234"
                  y1="870"
                  x2="234"
                  y2="1824"
                />
                <line className="whiteLine" x1="234" x2="234" y2="750" />
              </svg>
              <div className={styles.map}>
                <img src={mapAll} alt="" />
              </div>
              <div
                className={`${styles.map} ${styles.glow}`}
                ref={map => (this.map1 = map)}
              >
                <img src={map1} alt="" />
              </div>
              <div
                className={`${styles.map} ${styles.glow}`}
                ref={map => (this.map2 = map)}
              >
                <img src={map2} alt="" />
              </div>
              <div
                className={`${styles.map} ${styles.glow}`}
                ref={map => (this.map3 = map)}
              >
                <img src={map3} alt="" />
              </div>
              <div
                className={`${styles.map} ${styles.glow}`}
                ref={map => (this.map4 = map)}
              >
                <img src={map4} alt="" />
              </div>
              <div
                className={`${styles.map} ${styles.glow}`}
                ref={map => (this.map5 = map)}
              >
                <img src={map5} alt="" />
              </div>
            </div>
            <div className="approach-textContainerWrapper">
              <div
                className="approach-textContainer"
                ref={text => (this.text = text)}
              >
                <h3>THE ASIA STAGE</h3>
                <p>
                  Bringing startups to Asia is our edge. Based in Hong Kong, we
                  are connected to communities and strategic partners all over
                  Asia Pacific, spanning the region's largest conglomerates and
                  corporates in retail, real estate, entertainment, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
