import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TweenMax, TimelineMax, Power0, Sine } from "gsap";
import ContactButton from "../../utils/Button/ContactButton";
import Circle from "./Circle";
import { Link } from "gatsby";
import { approachSections } from '../../utils/helperFunctions'
export default class SectionSix extends Component {
  constructor() {
    super();
    this.dottedLine = null;
    this.svg = null;
    this.tl = null;
    this.text = null;
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      require("../../../assets/js/DrawSVGPlugin");
      TweenMax.set(this.svg, { autoAlpha: 0 });
      (this.tl = new TimelineMax({ paused: true }).fromTo(
        this.dottedLine,
        3.5,
        { drawSVG: "0%" },
        {
          drawSVG: "100%",
          ease: Power0.easeNone
        },
        "+=.5"
      )).to(this.text, 1.5, { autoAlpha: 1, ease: Sine.easeOut }, "-=1");
    }
  }

  componentDidUpdate() {
    const { activeSection } = this.props;
    if (activeSection === approachSections.SECTION_SIX) {
      TweenMax.to(this.svg, 0.5, { autoAlpha: 1, ease: Sine.easeOut });
      this.tl.play();
    }
  }

  render() {
    const { activeSection } = this.props;
    return (
      <section className={`${styles.section} relative h-full`}>
        <div className="container--approach">
          <div className="approach-flexContainer">
            <div className="approach-svg-container">
              <svg
                className="small"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 463.2 1824"
                ref={svg => (this.svg = svg)}
              >
                <g>
                  <polyline
                    className="yellowLine reduced"
                    points="231.6 1824 231.6 1172.3 230.7,1172.3 431.5,1172.3 431.5,1044 165.1,1044 165.1,1173.9 32,1173.9 32,914.2 431.5,914.2 431.5,784.4 32,784.4 32,654.5 431.5,654.5"
                    ref={dottedLine => (this.dottedLine = dottedLine)}
                  />
                  <polyline
                    className="whiteLine reduced"
                    points="431.5,654.5 32,654.5 32,784.4 431.5,784.4 431.5,914.2 32,914.2 32,1173.9 165.1,1173.9 165.1,1044 
		431.5,1044 431.5,1172.3 230.7,1172.3 231.6 1172.3 231.6 1824"
                  />
                </g>
                <Circle
                  delay="1"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="298"
                  cy="1172"
                  p1="298.2,1186.2 295.2,1186.2 297.5,1182.3 300.5,1182.3 308.3,1168.7 312.7,1168.7 302.7,1186.2 
                    298.2,1186.2"
                  p2="288.9,1160.1 309,1160.1 312.7,1166.5 308.3,1166.5 306.8,1164 291.1,1164"
                  p3="293.3,1185.1 283.3,1167.7 287,1161.3 289.2,1165.1 287.7,1167.7 295.6,1181.2"
                />

                <Circle
                  delay="2"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="430.8"
                  cy="1172"
                  p1="431.1,1186.2 428.1,1186.2 430.3,1182.3 433.2,1182.3 441.1,1168.7 445.5,1168.7 435.5,1186.2 
                  431.1,1186.2"
                  p2="421.7,1160.1 441.8,1160.1 445.5,1166.5 441.1,1166.5 439.6,1164 423.9,1164"
                  p3="426.1,1185.1 416.1,1167.7 419.8,1161.3 422,1165.1 420.5,1167.7 428.4,1181.2"
                />

                <Circle
                  delay="3"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="430.8"
                  cy="1042.5"
                  p1="431.1,1056.7 428.1,1056.7 430.3,1052.8 433.2,1052.8 441.1,1039.2 445.5,1039.2 435.5,1056.7 
                  431.1,1056.7"
                  p2="421.7,1030.6 441.8,1030.6 445.5,1037 441.1,1037 439.6,1034.5 423.9,1034.5"
                  p3="426.1,1055.6 416.1,1038.2 419.8,1031.8 422,1035.6 420.5,1038.2 428.4,1051.8"
                />

                <Circle
                  delay="4"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="298"
                  cy="1042.5"
                  p1="298.2,1056.7 295.2,1056.7 297.5,1052.8 300.5,1052.8 308.3,1039.2 312.7,1039.2 302.7,1056.7 
                  298.2,1056.7"
                  p2="288.9,1030.6 309,1030.6 312.7,1037 308.3,1037 306.8,1034.5 291.1,1034.5"
                  p3="293.3,1055.6 283.3,1038.2 287,1031.8 289.2,1035.6 287.7,1038.2 295.6,1051.8"
                />

                <Circle
                  delay="5"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="165.2"
                  cy="1042.5"
                  p1="165.4,1056.7 162.5,1056.7 164.7,1052.8 167.6,1052.8 175.5,1039.2 179.9,1039.2 169.9,1056.7 
                  165.4,1056.7"
                  p2="156.1,1030.6 176.2,1030.6 179.9,1037 175.5,1037 174,1034.5 158.3,1034.5"
                  p3="160.5,1055.6 150.5,1038.2 154.2,1031.8 156.4,1035.6 154.9,1038.2 162.8,1051.8"
                />

                <Circle
                  delay="6"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="165.2"
                  cy="1172"
                  p1="165.4,1186.2 162.5,1186.2 164.7,1182.3 167.6,1182.3 175.5,1168.7 179.9,1168.7 169.9,1186.2 
                  165.4,1186.2"
                  p2="156.1,1160.1 176.2,1160.1 179.9,1166.5 175.5,1166.5 174,1164 158.3,1164"
                  p3="160.5,1185.1 150.5,1167.7 154.2,1161.3 156.4,1165.1 154.9,1167.7 162.8,1181.2"
                />

                <Circle
                  delay="7"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="32.4"
                  cy="1172"
                  p1="32.6,1186.2 29.7,1186.2 31.9,1182.3 34.8,1182.3 42.7,1168.7 47.1,1168.7 37.1,1186.2 32.6,1186.2"
                  p2="23.3,1160.1 43.4,1160.1 47.1,1166.5 42.7,1166.5 41.2,1164 25.5,1164"
                  p3="27.7,1185.1 17.7,1167.7 21.4,1161.3 23.6,1165.1 22.1,1167.7 30,1181.2"
                />

                <Circle
                  delay="8"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="32.4"
                  cy="1042.5"
                  p1="32.6,1056.7 29.7,1056.7 31.9,1052.8 34.8,1052.8 42.7,1039.2 47.1,1039.2 37.1,1056.7 32.6,1056.7"
                  p2="23.3,1030.6 43.4,1030.6 47.1,1037 42.7,1037 41.2,1034.5 25.5,1034.5"
                  p3="27.7,1055.6 17.7,1038.2 21.4,1031.8 23.6,1035.6 22.1,1038.2 30,1051."
                />

                <Circle
                  delay="9"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="32.4"
                  cy="913"
                  p1="32.6,927.2 29.7,927.2 31.9,923.3 34.8,923.3 42.7,909.8 47.1,909.8 37.1,927.2 32.6,927.2"
                  p2="23.3,901.1 43.4,901.1 47.1,907.5 42.7,907.5 41.2,905 25.5,905"
                  p3="27.7,926.1 17.7,908.7 21.4,902.3 23.6,906.2 22.1,908.7 30,922."
                />

                <Circle
                  delay="10"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="165.2"
                  cy="913"
                  p1="165.4,927.2 162.5,927.2 164.7,923.3 167.6,923.3 175.5,909.8 179.9,909.8 169.9,927.2 165.4,927.2"
                  p2="156.1,901.1 176.2,901.1 179.9,907.5 175.5,907.5 174,905 158.3,905"
                  p3="160.5,926.1 150.5,908.7 154.2,902.3 156.4,906.2 154.9,908.7 162.8,922.3"
                />

                <Circle
                  delay="11"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="298"
                  cy="913"
                  p1="298.2,927.2 295.2,927.2 297.5,923.3 300.5,923.3 308.3,909.8 312.7,909.8 302.7,927.2 298.2,927.2"
                  p2="288.9,901.1 309,901.1 312.7,907.5 308.3,907.5 306.8,905 291.1,905"
                  p3="293.3,926.1 283.3,908.7 287,902.3 289.2,906.2 287.7,908.7 295.6,922.3"
                />

                <Circle
                  delay="12"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="430.8"
                  cy="913"
                  p1="431.1,927.2 428.1,927.2 430.3,923.3 433.2,923.3 441.1,909.8 445.5,909.8 435.5,927.2 431.1,927.2"
                  p2="421.7,901.1 441.8,901.1 445.5,907.5 441.1,907.5 439.6,905 423.9,905"
                  p3="426.1,926.1 416.1,908.7 419.8,902.3 422,906.2 420.5,908.7 428.4,922.3"
                />

                <Circle
                  delay="13"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="430.8"
                  cy="783.5"
                  p1="431.1,797.7 428.1,797.7 430.3,793.8 433.2,793.8 441.1,780.3 445.5,780.3 435.5,797.7 431.1,797.7"
                  p2="421.7,771.6 441.8,771.6 445.5,778.1 441.1,778.1 439.6,775.5 423.9,775.5"
                  p3="426.1,796.7 416.1,779.2 419.8,772.8 422,776.7 420.5,779.2 428.4,792.8"
                />
                <Circle
                  delay="14"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="430.8"
                  cy="783.5"
                  p1="431.1,797.7 428.1,797.7 430.3,793.8 433.2,793.8 441.1,780.3 445.5,780.3 435.5,797.7 431.1,797.7"
                  p2="421.7,771.6 441.8,771.6 445.5,778.1 441.1,778.1 439.6,775.5 423.9,775.5"
                  p3="426.1,796.7 416.1,779.2 419.8,772.8 422,776.7 420.5,779.2 428.4,792.8"
                />
                <Circle
                  delay="15"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="298"
                  cy="783.5"
                  p1="298.2,797.7 295.2,797.7 297.5,793.8 300.5,793.8 308.3,780.3 312.7,780.3 302.7,797.7 298.2,797.7"
                  p2="288.9,771.6 309,771.6 312.7,778.1 308.3,778.1 306.8,775.5 291.1,775.5"
                  p3="293.3,796.7 283.3,779.2 287,772.8 289.2,776.7 287.7,779.2 295.6,792.8"
                />
                <Circle
                  delay="16"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="165.2"
                  cy="783.5"
                  p1="165.4,797.7 162.5,797.7 164.7,793.8 167.6,793.8 175.5,780.3 179.9,780.3 169.9,797.7 165.4,797.7"
                  p2="156.1,771.6 176.2,771.6 179.9,778.1 175.5,778.1 174,775.5 158.3,775.5"
                  p3="160.5,796.7 150.5,779.2 154.2,772.8 156.4,776.7 154.9,779.2 162.8,792.8"
                />
                <Circle
                  delay="17"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="32.4"
                  cy="783.5"
                  p1="32.6,797.7 29.7,797.7 31.9,793.8 34.8,793.8 42.7,780.3 47.1,780.3 37.1,797.7 32.6,797.7"
                  p2="23.3,771.6 43.4,771.6 47.1,778.1 42.7,778.1 41.2,775.5 25.5,775.5"
                  p3="27.7,796.7 17.7,779.2 21.4,772.8 23.6,776.7 22.1,779.2 30,792.8"
                />
                <Circle
                  delay="18"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="32.4"
                  cy="654"
                  p1="32.6,668.2 29.7,668.2 31.9,664.3 34.8,664.3 42.7,650.8 47.1,650.8 37.1,668.2 32.6,668.2"
                  p2="23.3,642.1 43.4,642.1 47.1,648.6 42.7,648.6 41.2,646 25.5,646"
                  p3="27.7,667.2 17.7,649.7 21.4,643.3 23.6,647.2 22.1,649.7 30,663.3"
                />
                <Circle
                  delay="19"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="165.2"
                  cy="654"
                  p1="165.4,668.2 162.5,668.2 164.7,664.3 167.6,664.3 175.5,650.8 179.9,650.8 169.9,668.2 165.4,668.2"
                  p2="156.1,642.1 176.2,642.1 179.9,648.6 175.5,648.6 174,646 158.3,646"
                  p3="160.5,667.2 150.5,649.7 154.2,643.3 156.4,647.2 154.9,649.7 162.8,663.3"
                />
                <Circle
                  delay="20"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="298"
                  cy="654"
                  p1="298.2,668.2 295.2,668.2 297.5,664.3 300.5,664.3 308.3,650.8 312.7,650.8 302.7,668.2 298.2,668.2"
                  p2="288.9,642.1 309,642.1 312.7,648.6 308.3,648.6 306.8,646 291.1,646"
                  p3="293.3,667.2 283.3,649.7 287,643.3 289.2,647.2 287.7,649.7 295.6,663.3"
                />
                <Circle
                  delay="21"
                  isVisible={activeSection === approachSections.SECTION_SIX}
                  cx="430.8"
                  cy="654"
                  p1="431.1,668.2 428.1,668.2 430.3,664.3 433.2,664.3 441.1,650.8 445.5,650.8 435.5,668.2 431.1,668.2 "
                  p2="421.7,642.1 441.8,642.1 445.5,648.6 441.1,648.6 439.6,646 423.9,646"
                  p3="426.1,667.2 416.1,649.7 419.8,643.3 422,647.2 420.5,649.7 428.4,663.33"
                />
              </svg>
            </div>
            <div className="approach-textContainerWrapper">
              <div
                className="approach-textContainer"
                ref={text => (this.text = text)}
              >
                <h3>WORK WITH US</h3>
                <p className="mb-16">
                  We are the biggest supporters of our portfolio companies and
                  celebrate their successes every step of the way. If you're
                  building something exciting, we'd love to hear from you.
                </p>
                <div className="button-container w-80">
                  <Link to="/contact">
                    <ContactButton icon="flat" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
