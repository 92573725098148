import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TweenMax, TimelineMax, Power0, Sine } from "gsap";
export default class LogoAnimation extends Component {
  constructor() {
    super();

    this.line1 = null;
    this.line2 = null;
    this.line3 = null;
    this.line4 = null;
    this.line5 = null;
    this.line6 = null;
    this.line7 = null;
    this.line8 = null;
    this.line9 = null;
    this.line10 = null;
    this.line11 = null;
    this.line12 = null;
    this.line13 = null;
    this.line14 = null;
    this.line15 = null;
    this.line16 = null;
    this.line17 = null;
    this.line18 = null;
    this.logo = null;
    this.logo1 = null;
    this.logo2 = null;
    this.logo3 = null;
    this.logoFill1 = null;
    this.logoFill2 = null;
    this.logoFill3 = null;
    this.dottedLine = null;
    this.lines = null;
    this.tl = null;
    this.logoTL = null;
    this.btn = null;
    this.graphics = null;
    this.logoContainer = null;
  }
  componentDidMount() {
    TweenMax.set(this.dottedLine, { autoAlpha: 0 });

    if (typeof window !== "undefined") {
      require("../../../../assets/js/DrawSVGPlugin");
      this.logoTL = new TimelineMax({ paused: true })
        .set(this.logo3, { autoAlpha: 1 })
        .set(this.logo1, { autoAlpha: 1 })
        .set(this.logo2, { autoAlpha: 1 })
        .fromTo(
          this.logo3,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut }
        )
        .to(this.logoFill3, 0.7, {
          autoAlpha: 1,
          fill: "#fec659",
          ease: Power0.easeOut
        })
        .fromTo(
          this.logo2,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill2, 0.7, {
          autoAlpha: 1,
          fill: "#fec659",
          ease: Power0.easeOut
        })
        .fromTo(
          this.logo1,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill1, 0.7, {
          autoAlpha: 1,
          fill: "#fec659",
          ease: Power0.easeOut
        })
        .set(this.dottedLine, { autoAlpha: 1 })
        .fromTo(
          this.dottedLine,
          1.5,
          { drawSVG: "0%" },
          {
            drawSVG: "100%",
            ease: Sine.easeOut
          }
        )
        .delay(1.5);

      this.tl = new TimelineMax({ paused: true })
        .set(this.lines, { autoAlpha: 1 })
        .fromTo(
          this.line1,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut }
        )
        .fromTo(
          this.line2,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line3,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line4,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line5,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line6,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line7,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line8,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line9,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line10,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line11,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line12,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line13,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line14,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line15,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line16,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line17,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line18,
          1.2,
          { drawSVG: "0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .delay(1);

      this.logoTL.play();
      this.tl.play();
    }
  }
  render() {
    return (
      <div
        className={styles.svgContainer}
        ref={graphics => (this.graphics = graphics)}
      >
        <div className={styles.lines} ref={lines => (this.lines = lines)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1367 769">
            <clipPath id="approach-logoa">
              <rect
                className={styles.a}
                x="-1110.22"
                y="-1328.4"
                width="35.53"
                height="155.07"
              />
            </clipPath>
            <clipPath id="approach-logob">
              <rect
                className={styles.a}
                x="-1110.22"
                y="-560.4"
                width="35.53"
                height="155.07"
              />
            </clipPath>
            <line
              x1="339.016"
              y1="297"
              x2="339.016"
              y2="0"
              className={styles.linePath}
              ref={line => (this.dottedLine = line)}
            />
            <line
              x1="339.016"
              y1="297"
              x2="339.016"
              y2="0"
              className={styles.linePathDashed}
            />
            <polyline
              className={styles.d}
              points="339.89 503.42 312.76 503.42 332.98 468.4 359.75 468.4 430.66 345.57 471.09 345.57 379.95 503.42 339.89 503.42"
              ref={logo => (this.logo1 = logo)}
            />
            <polygon
              className={styles.d}
              points="255.22 267.47 437.49 267.47 471.09 325.65 430.65 325.65 417.26 302.48 275.44 302.48 255.220 267.47"
              ref={logo => (this.logo2 = logo)}
            />
            <polygon
              className={styles.d}
              points="295.45 493.98 204.31 336.12 237.91 277.94 258.13 312.96 244.75 336.14 315.66 458.97 295.45 493.98"
              ref={logo => (this.logo3 = logo)}
            />
            <polyline
              className="fill-brand-white opacity-0"
              points="339.89 503.42 312.76 503.42 332.98 468.4 359.75 468.4 430.66 345.57 471.09 345.57 379.95 503.42 339.89 503.42"
              ref={logo => (this.logoFill1 = logo)}
            />
            <polygon
              className="fill-brand-white opacity-0"
              points="255.22 267.47 437.49 267.47 471.09 325.65 430.65 325.65 417.26 302.48 275.44 302.48 255.22 267.47"
              ref={logo => (this.logoFill2 = logo)}
            />
            <polygon
              className="fill-brand-white opacity-0"
              points="295.45 493.98 204.31 336.12 237.91 277.94 258.13 312.96 244.75 336.14 315.66 458.97 295.45 493.98"
              ref={logo => (this.logoFill3 = logo)}
            />
            <g className={styles.e}>
              <line
                className={styles.f}
                x1="9.95"
                y1="-2.62"
                x2="453.92"
                y2="768.91"
                ref={line => (this.line1 = line)}
              />
              <line
                className={styles.f}
                x1="49.92"
                y1="-2.62"
                x2="494.5"
                y2="769.11"
                ref={line => (this.line2 = line)}
              />
              <line
                className={styles.f}
                x2="544.52"
                y2="769.11"
                x1="99.94"
                y1="-2.62"
                ref={line => (this.line3 = line)}
              />
              <line
                className={styles.f}
                x1="76.52"
                y1="-2.62"
                x2="521.1"
                y2="769.11"
                ref={line => (this.line4 = line)}
              />
              <line
                className={styles.f}
                x1="630.53"
                y1="-1.19"
                x2="186.75"
                y2="769.14"
                ref={line => (this.line5 = line)}
              />
              <line
                className={styles.f}
                x1="160.38"
                y1="768.35"
                x2="604.53"
                y2="-2.62"
                ref={line => (this.line6 = line)}
              />
              <line
                className={styles.f}
                x1="581.53"
                y1="-2.18"
                x2="137.32"
                y2="768.91"
                ref={line => (this.line7 = line)}
              />
              <line
                className={styles.f}
                x1="227.01"
                y1="769.11"
                x2="670.77"
                y2="-1.19"
                ref={line => (this.line8 = line)}
              />
              <line
                className={styles.f}
                x1="399.43"
                y1="-2.18"
                x2="-44.9"
                y2="769.11"
                ref={line => (this.line9 = line)}
              />
              <line
                className={styles.f}
                x1="-4.43"
                y1="769.11"
                x2="439.9"
                y2="-2.18"
                ref={line => (this.line10 = line)}
              />
              <line
                className={styles.f}
                x1="726.88"
                y1="769.55"
                x2="282.04"
                y2="-2.62"
                ref={line => (this.line11 = line)}
              />
              <line
                className={styles.f}
                x1="241.57"
                y1="-2.62"
                x2="686.16"
                y2="769.11"
                ref={line => (this.line12 = line)}
              />
              <line
                className={styles.f}
                x1="0.5"
                y1="267.52"
                x2="1366.5"
                y2="267.52"
                ref={line => (this.line13 = line)}
              />
              <line
                className={styles.f}
                x1="1366.5"
                y1="302.54"
                x2="0.5"
                y2="302.54"
                ref={line => (this.line14 = line)}
              />
              <line
                className={styles.f}
                x1="1366.5"
                y1="345.5"
                x2="0.5"
                y2="345.5"
                ref={line => (this.line15 = line)}
              />
              <line
                className={styles.f}
                x1="0.5"
                y1="325.73"
                x2="1366.5"
                y2="325.73"
                ref={line => (this.line16 = line)}
              />
              <line
                className={styles.f}
                x1="0.5"
                y1="468.5"
                x2="1366.5"
                y2="468.5"
                ref={line => (this.line17 = line)}
              />
              <line
                className={styles.f}
                x1="1366.5"
                y1="503.51"
                x2="0.5"
                y2="503.51"
                ref={line => (this.line18 = line)}
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
